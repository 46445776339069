import React from "react";
import { IconButton } from "@mui/material";
import { DrawerProps } from "./Drawer.types";
import { DrawerHeader, StyledDrawer } from "./Drawer.styles";
import MainMenu from "../MainMenu/MainMenu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

export const Drawer = ({ open, onClose }: DrawerProps) => {
    return (
        <div>
            <StyledDrawer open={open} variant="permanent">
                <DrawerHeader>
                    <IconButton onClick={onClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </DrawerHeader>
                <MainMenu open={open} />
            </StyledDrawer>
        </div>
    );
};

export default Drawer;
