import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        htmlFontSize: 16,
        fontWeightLight: 300,
        fontWeightMedium: 700,
        fontWeightRegular: 400,
    },
    palette: {
        primary: { main: "#29265B", contrastText: "#ffffff" },
        error: { main: "#f44336", dark: "#a52a21" },
        warning: { main: "#efad41" },
        background: {
            paper: "#fff",
        },
    },
});
