import React from "react";
import {
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemButton,
} from "@mui/material";
import { MenuItemProps } from "./MenuItem.types";

export const MenuItem = ({ icon, text, hideText }: MenuItemProps) => {
    return (
        <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
                sx={{
                    minHeight: 48,
                    justifyContent: hideText ? "initial" : "center",
                    px: 2.5,
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: 0,
                        mr: hideText ? 3 : "auto",
                        justifyContent: "center",
                    }}
                >
                    {icon}
                </ListItemIcon>
                <ListItemText
                    primary={text}
                    sx={{ opacity: hideText ? 1 : 0 }}
                />
            </ListItemButton>
        </ListItem>
    );
};

export default MenuItem;
