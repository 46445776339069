import React from "react";
import { IconButton, Toolbar, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { AppBarProps } from "./AppBar.types";
import { AppBar as StyledAppbar } from "./AppBar.styles";

export const AppBar = ({ onClick, open }: AppBarProps) => {
    return (
        <StyledAppbar position="fixed" open={open}>
            <Toolbar>
                <IconButton onClick={onClick} edge="start" color="inherit">
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6">Convergage</Typography>
            </Toolbar>
        </StyledAppbar>
    );
};

export default AppBar;
