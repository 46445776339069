import React from "react";
import { List } from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { MainMenuProps } from "./MainMenu.types";
import MenuItem from "src/components/molucules/MenuItem/MenuItem";

const menu = [
    {
        text: "Templates",
        icon: <DashboardIcon />,
    },
];

export const Layout = ({ open }: MainMenuProps) => {
    return (
        <List>
            {menu.map((item) => (
                <MenuItem
                    key={item.text}
                    hideText={open}
                    icon={item.icon}
                    text={item.text}
                />
            ))}
        </List>
    );
};

export default Layout;
